/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Red+Hat+Display:wght@700&display=swap');

:root {
  --main-color: #2F80ED;
  --main-color-dark: #0A1F44;
  --main-color-emphasize: #1A76E0;
  --main-bg-color: #151c23;
  --left-bar-text-color: white;
  --left-bar-text-background: transparent;
}

html, body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: white;
}

h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: var(--main-color-dark);
}

p {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--main-color-dark);
}


/* BUTTONS */

button.btn {
  all: unset;
  border-radius: 4px;
  padding: 6px 16px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background-color: white;
  border: 1px solid black;
}

button.btn .button-img {
  width: 20px;
  height: 20px;
  top: 5px;
  position: relative;
  left: -5px;
}

button.btn.btn-md {
  padding: 8px 25px;
  line-height: 17px;
  font-size: 14px;
}

button.btn.btn-cancel {
  color: #999999;
  border: 1px solid #999999;
}


button.btn.btn-basic {
  border: none;
  color: var(--main-color);
}

button.btn.btn-basic:hover {
  border: none;
  background: none;
  color: var(--main-color-emphasize);
}

button.btn.btn-action {
  color: white;
  background: var(--main-color);
  border: 1px solid var(--main-color);
}

button.btn.btn-danger {
  color: white;
  background: #E95F59;
  border: 1px solid #E95F59;
}

button.btn.btn-danger:not([disabled]):hover {
  color: white;
  background: #bf4a45!important;
  border: 1px solid #bf4a45!important;
}

button.btn.btn-wide {
  max-width: 200px;
  text-align: center;
}


button.btn:hover:not([disabled]) img {
  filter: brightness(0) invert(1);
}

button.btn:hover:not([disabled]) {
  cursor: pointer;
  background: var(--main-color);
  color: white;
  border-color: transparent;
}

button.btn:disabled img {
  filter: opacity(30%);
}

button.btn:disabled {
  background-color: #d3d3d3;
  border-color: #d3d3d3;
  color: #9c9a9a;
}

button.btn.btn-cancel:hover:not([disabled]) {
  background: #F2F5F9;
  color: black;
}

button.btn.btn-action:hover:not([disabled]) {
  background: var(--main-color-emphasize);
}

button.btn.btn-sm {
  height: 18px;
  line-height: 18px;
}

/* FORMS */

.form-separated-container {
  border-top: 1px solid #E1E4E8;
  padding-top: 10px;
  margin-top: 10px;
}

.form-element {
  text-align: left;
  display: inline-block;
}

.form-element label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: var(--main-color-dark);
  margin-bottom: 8px;
  margin-top: 8px;
}


.form-element input, .form-element select {
  display: block;
  background: #F2F5F9;
  box-sizing: border-box;
  border-radius: 1px;
  height: 40px;
  padding: 10px;
  border: none;
  margin-bottom: 8px;
}

.form-element select {
  background: white;
  border: 1px solid #CFD4DA;
  border-radius: 4px;
}

.form-element.required input {
  border: 1px solid #CFD4DA;
}

.form-element textarea, .form-element.required select {
  background: #F2F5F9;
  border: 1px solid #CFD4DA;
  box-sizing: border-box;
  border-radius: 1px;
  padding: 10px;

  margin-bottom: 8px;
}


.form-element *::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #C7CBCF;
}


.form-distance {
  display: inline-block;
}

.form-element input.unvalid, .form-element select.unvalid {
  border: 2px solid #f5aeae;
  padding: 8px;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 24px;
  color: #3E4C59 !important;
}


/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background: #F2F5F9;
  border: 1px solid #CFD4DA;
  box-sizing: border-box;
  border-radius: 1px;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: var(--main-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-element.required input:focus, input:focus, select:focus, textarea:focus {
  outline: none;
  border: 1px solid var(--main-color);
}


/* RIGHT SIDEBAR */
.right-sidebar-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.right-sidebar-container.active {
  visibility: visible;
  opacity: 1;
  z-index: 3;
}

.right-sidebar-container .right-sidebar {
  position: absolute;
  right: 0;
  width: 650px;
  max-width: 100vw;
  height: 100vh;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  background-color: white;
  border-radius: 2px;
  transition: all 0.4s ease-in-out;
  z-index: 2;
  display: flex;
  justify-content: center;
  transform: translateX(100%);
}

.right-sidebar-container.active .right-sidebar {
  animation: 0.4s 1 slideIn;
  transform: translateX(0%);
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}


.right-sidebar-container .header {
  /* padding-bottom: 40px;
   margin-bottom: 40px;
   border-bottom: 1px solid #E1E4E8;*/
}

.right-sidebar-container .header .title {
  font-size: 16px;
  line-height: 28px;
  color: var(--main-color-dark);
  margin-top: 0;
  margin-bottom: 0;
}

.right-sidebar-container .header .subtitle {
  font-size: 12px;
  line-height: 16px;
  color: #4E5D78;
}

.right-sidebar-container .close-icon {
  cursor: pointer;
  position: absolute;
  right: 46px;
  top: 17px;
  color: #8A94A6;
}


.right-sidebar-container .right-sidebar-content {
  width: 100%;
  padding: 35px;
}

.dialog-container .dialog .overlay,
.right-sidebar-container .overlay {
  background: rgba(0, 0, 0, .5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.right-sidebar main {
  padding-top: 20px;
}

.blur {
  /*filter: blur(1px);*/
}

/* CARD */
.card.active {
  border: 1px solid var(--main-color-emphasize);
}

.card.needsAction {
  box-shadow: 0 2px 2px rgb(244 155 54 / 5%), 0 4px 8px rgb(244 155 54 / 50%);
}

.card.needsAction:hover {
  box-shadow: 0 2px 4px rgb(244 155 54 / 15%), 0 8px 16px rgb(244 155 54 / 65%);
}

.card-container {
  padding-left: 64px;
  padding-bottom: 48px;
  display: inline-block;
}

.card {
  background: #FFFFFF;
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16);
  border-radius: 4px;
  transition: all 0.4s ease-in-out;
  margin-left: 4px;
  margin-right: 4px;
}

.card:hover {
  box-shadow: 0 2px 4px rgba(40, 41, 61, 0.04), 0 8px 16px rgba(96, 97, 112, 0.16);
  cursor: pointer;
}

.card-content {
  padding: 18px;
}

.card-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 4px;
  margin-top: 8px;
  clear: both;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-header .card-title, .card-header .card-subtitle {
  padding-left: 18px;
}

.card-header .card-title {
  margin-top: 18px;
}

.card-subtitle {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin-top: 4px;
  clear: both;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-footer {
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin: 0 18px;
  padding: 18px 0;
  border-top: 1px solid #DEDEDE;
}

.card .card-img-container {
  position: relative;
  width: 100%;
  padding-bottom: 50%;
}

.card .card-img-container .card-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card {
  margin-bottom: 40px;
}

/* COLORS */
.error-color {
  color: #f56c6c;
}

/* PARAGRAPH */
.paragraph-label {
  font-size: 12px;
  line-height: 20px;
  color: #8A94A6;
  margin-bottom: 8px;
  margin-top: 40px;
}

.paragraph-content {
  font-size: 14px;
  line-height: 24px;
  color: var(--main-color-dark);
  margin: 0;
}

/* WIDTH CLASSES */
.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

/* DISPLAY CLASSES */

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

/* PULL(FLOAT) CLASSES */

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

/* LIST */
.list-item {
  border-top: 1px solid #E1E4E8;
  padding-top: 25px;
  padding-bottom: 25px;
}

.list-item:last-of-type {
  border-bottom: 1px solid #E1E4E8;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.cesium-viewer .cesium-widget-credits {
  display: none !important;
}

/* POTREE */
#sidebar_root {
  font-family: 'Inter', sans-serif !important;
  font-size: 12px;
  padding-left: 28px;
}

.accordion > h3 {
  background-color: var(--main-color) !important;
  text-shadow: none;
  box-shadow: 0 3px 3px #22222236;
  border: none;
  padding: 10px;
  color: white;
}

.accordion > h3:hover {
  background-color: var(--main-color-emphasize) !important;
  filter: none !important;
}

.pv-menu-list {
  color: white;
}

.pv-menu-list > li > .ui-slider {
  border-radius: 0;
  height: 15px;
  background: #e0e0e0 !important;
  border: none !important;
}


.pv-menu-list > li > .ui-slider > .ui-slider-handle {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 0;
  background: var(--main-color) !important;
  border: none !important;
  top: -2px;
}

.pv-menu-list > li > .ui-slider {
  background-color: #c3c1c1 !important;
}

.potree_menu_toggle {
  width: 25px;
  height: 25px;
}

#potree_branding, #potree_languages {
  display: none !important;
}

#potree_sidebar_container {
  background-color: var(--main-bg-color) !important;
}

.ui-state-default {
  background: #f6f6f6 !important;
  color: var(--main-color-dark) !important;
  border: none !important;
}

.ui-state-active {
  background: var(--main-color-emphasize) !important;
  color: white !important;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
  background: #cccccc !important;
  color: white !important;
}

@keyframes snackbarIn {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes snackbarOut {
  to {
    transform: translateY(-100px);
    opacity: 0;
  }
}
